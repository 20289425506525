import {
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { ScreenSizes } from "core/home/types";
import { useAppValues } from "lib/context/AppContext";

interface OwnProps {
  header?: string;
  isOpen: boolean;
  onClose?: () => void;
  children?: JSX.Element;
  footer?: JSX.Element;
  transparent?: boolean;
  fullWidth?: boolean;
}

type Props = OwnProps & Partial<ModalProps>;

/**
 * @deprecated - use Modal from beta instead
 * @param props
 * @returns
 */
export default function Modal(props: Props): JSX.Element {
  const { header, isOpen, children, footer, transparent, fullWidth } = props;
  const { screenSize } = useAppValues().getAppValues();
  const isNotPhone = screenSize !== ScreenSizes.XS;

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        backgroundColor={transparent ? "transparent" : undefined}
        minWidth={isNotPhone ? "672px" : undefined}
        alignItems={fullWidth ? undefined : "center"}
      >
        {Boolean(header) && <ModalHeader>{header}</ModalHeader>}
        <ModalBody justifyContent="center" alignItems="center">
          {children}
        </ModalBody>
        {Boolean(footer) && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
}
